import { useState, useEffect } from 'react';

const useConfigurations = () => {
  const [configurations, setConfigurations] = useState({});

  useEffect(() => {
    const sessionStorageConfig = sessionStorage.getItem("configurations")
    if (sessionStorageConfig == null) {
      fetch('assets/IP_Configrations.json')
        .then((response) => response.json())
        .then((data) => {
          setConfigurations(data)
          sessionStorage.setItem("configurations", btoa(JSON.stringify(data)))
        })
        .catch((error) => console.error('Error fetching the JSON file:', error));
    } else {
      const atobConfig = atob(sessionStorageConfig)
      const parseConfig = JSON.parse(atobConfig)
      setConfigurations(parseConfig)
    }
  }, []);

  return configurations;
};

export default useConfigurations;
