import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import ChatBot from "./ChatBot";

import Footer from "./Footer";
import { useLocation } from 'react-router-dom';
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
const Layout = () => {
  return (
    <>
      <ScrollToTop />
      <ul className="lines__over">
        <li className="lines__over__line" />
        <li className="lines__over__line" />
        <li className="lines__over__line" />
        <li className="lines__over__line" />
        <li className="lines__over__line" />
      </ul>
      <Header />
      <ChatBot />
      <Outlet />
      <Footer />
    </>
  )
};

export default Layout;
